.helplimit{
    position: absolute;
    right : 15px;
    top : 15px;
}
.theme1{
    flex-wrap: nowrap;
    flex-direction : row !important;
    align-items: 'center';
    justify-content: center;
}
.theme1_col{
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}