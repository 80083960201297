.nano{
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    /* margin-top: 60px; */
    padding: 50px 20px;
    /* border-radius: 20px; */
    font-size: 24px;
    overflow: hidden;
}
.nano div{
    position: relative;
}
.copy_light{
    /* display: flex;
    align-items: center;
    gap: 20px; */
}
.nano
.coverBG{
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}
.coverImageQr{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.qrcodeBG{
    background: #fff;
    padding: 20px;
}

@media (max-width : 600px) {
    .preview_mm.nano{
        width: 100% !important;
        height: auto !important;
    }
    .group_form_preview{
        top : 0 !important;
    }
    .group_header_filter{
        flex-direction: column;
        gap: 10px;
    }
    .group_header_filter button { 
        width: 100%;
    }
}