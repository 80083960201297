.body{
    display : none;
}
.group_list_cat{
    position: relative;
}
.group_list_cat::after{
    content: '';
    position: absolute;
    top: 30px;
    left: 9px;
    bottom: 0;
    width: 1px;
    height: calc(100% - 55.2px);
    border-left: 1px dashed rgb(100 115 130) ;
    /* background-color: black; */
}
.list_cat_item{
    position: relative;
}
.list_cat_item::after{
    content: '';
    position: absolute;
    left: -9px;
    width: 21px;
    height: 1px;
    /* background-color: black; */
    border-top: 1px dashed rgb(100 115 130) ;
}